import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import TextCharacter from "../../media/TextCharacter.ico";
import "animate.css";

const Hero = () => {
  const wave1Ref = useRef(null);
  const wave2Ref = useRef(null);
  const wave3Ref = useRef(null);
  const wave4Ref = useRef(null);

  useEffect(() => {
    const disableAnimations = window.innerWidth < 1024;

    const waveAnimation = (element, duration, delay) => {
      gsap.to(element, {
        duration: disableAnimations ? 1e20 : duration,
        repeat: -1,
        x: -48,
        yoyo: true,
        ease: "cubic-bezier(.55, .5, .45, .5)",
        delay: delay,
      });
    };

    waveAnimation(wave1Ref.current, 6, -2);
    waveAnimation(wave2Ref.current, 8, -3);
    waveAnimation(wave3Ref.current, 10, -4);
    waveAnimation(wave4Ref.current, 14, -5);
  }, []);

  return (
    <div classname="waves ">
      <div className="header ">
        <div className="inner-header flex-wrap flex items-center justify-center">
          <div className="relative px-4 py-8 sm:px-6 sm:py-12 lg:py-16 lg:px-8 sm:py-10">
            <h1 className="wave_title mt-12 text-center font-extrabold tracking-tight sm:mt-12 md:mt-16 lg:mt-28 xlg:mt-28">
              <span className="block text-white flex items-center justify-center text-5xl sm:text-7xl lg:text-7xl xl:text-8xl">
                WebFact
                <img
                  src={TextCharacter}
                  alt="WebFactory Text Character"
                  className="inline mt-3 mr-1 h-7 w-7 xs:mt-2 sm:mt-5 sm:h-10 sm:w-10 md:mt-5 md:h-10 md:w-10 lg:mt-6 lg:h-10 lg:w-10 xl:mt-7 xl:h-14 xl:w-14"
                />
                ry
              </span>
            </h1>

            <p className="mt-4 max-w-md mx-auto text-center text-lg text-gray-200 sm:max-w-2xl font-semibold sm:text-xl lg:text-2xl">
              Your Blueprint to Online Business Excellence.
            </p>
            <div className="mt-6 mb-12 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                <Link
                  to="/contact"
                  title="Get a free quote"
                  className="flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-blue-700 bg-white hover:bg-blue-50 sm:px-6 sm:py-3 text-sm sm:text-md"
                >
                  Free Quote
                </Link>
                <Link
                  href="/team"
                  title="Meet our team"
                  className="flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-100 bg-opacity-10 hover:bg-opacity-70 sm:px-6 sm:py-3 text-sm sm:text-md"
                >
                  Meet The Team
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                ref={wave1Ref}
                xlinkHref="#gentle-wave"
                x={48}
                y={0}
                fill="rgba(255,255,255,0.7"
              />
              <use
                ref={wave2Ref}
                xlinkHref="#gentle-wave"
                x={48}
                y={3}
                fill="rgba(255,255,255,0.5)"
              />
              <use
                ref={wave3Ref}
                xlinkHref="#gentle-wave"
                x={48}
                y={5}
                fill="rgba(255,255,255,0.3)"
              />
              <use
                ref={wave4Ref}
                xlinkHref="#gentle-wave"
                x={48}
                y={7}
                fill="#fff"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default Hero;
