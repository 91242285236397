import React from "react";
import { Switch, Route } from "react-router-dom";
import Navigation from "./components/Skeleton/Navigation";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Footer from "./components/Skeleton/Footer";
import Thanks from "./pages/Thanks";
import ResponsiveDesign from "./components/Solutions/ResponsiveDesign";
import SearchEngineOptimization from "./components/Solutions/SearchEngineOptimization";
import WebsiteMaintenance from "./components/Solutions/WebsiteMaintenance";
import SaaSApplications from "./components/Solutions/SaaSApplications";
import Automation from "./components/Solutions/Automation";
import CustomEmails from "./components/Solutions/CustomEmails";

const App = () => {
  return (
    <div>
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/team" component={Team} />
        <Route path="/contact" component={Contact} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/responsive-web-design" component={ResponsiveDesign} />
        <Route
          path="/search-engine-optimization"
          component={SearchEngineOptimization}
        />
        <Route path="/website-maintenance" component={WebsiteMaintenance} />
        <Route path="/saas-applications" component={SaaSApplications} />
        <Route path="/automation" component={Automation} />
        <Route path="/custom-emails" component={CustomEmails} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
