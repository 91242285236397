import Hero from "../components/Home/Hero";
import Features from "../components/Home/Features";
import FeaturesCont from "../components/Home/FeaturesCont";
import CTA from "../components/Home/CTA";

const Home = () => {
  return (
    <div>
      <Hero />
      <Features />
      <FeaturesCont />
      <CTA />
    </div>
  );
};

export default Home;
