import TeamHero from "../components/Team/TeamHero";
import People from "../components/Team/People";
import About from "../components/Team/About";

export default function Team() {
  return (
    <div className="team-bg">
      <div className="hidden md:block">
        <TeamHero />
      </div>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="bg-white space-y-10 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0 shadow-2xl">
          <div className="mb-8 lg:mb-0">
            <About />
          </div>
          <People />
        </div>
      </div>
    </div>
  );
}
