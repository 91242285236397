import React from "react";

const SaaSApplications = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Unlock the Benefits of SaaS Applications
        </h2>
        <p className="text-lg text-white mb-8">
          We design and develop SaaS applications tailored to your business
          needs, providing powerful tools and scalability.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Cost-Effective & Scalable
            </h3>
            <p className="text-lg text-blue-200">
              SaaS applications offer a cost-effective solution for businesses,
              with lower upfront costs and a flexible subscription model. As
              your business grows, SaaS applications can be easily scaled to
              accommodate more users and increased demand.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Accessibility & Maintenance
            </h3>
            <p className="text-lg text-blue-200">
              SaaS applications are cloud-based, allowing users to access them
              from anywhere with an internet connection. This promotes remote
              work and collaboration among teams. Additionally, SaaS providers
              handle maintenance and updates, ensuring your applications stay
              up-to-date and secure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaaSApplications;
