import Logan from "../../media/logan.jpg";
import Christian from "../../media/Christian.jpg";
import svgList from "../utilities";

const people = [
  {
    name: "Logan Johnson",
    role: "Co-Founder & Senior Engineer",
    imageUrl: Logan,
    bio: `Hello, I’m Logan Johnson, an accomplished full-stack developer proficient in a variety of programming languages. I specialize in the development of web applications and websites, offering customized solutions to specific challenges facing today’s businesses. Using the power of code, I provide innovative and effective solutions that help businesses overcome challenges and succeed in today’s competitive landscape.`,
    icons: [
      svgList("GitHub", "https://github.com/findlogan"),
      svgList(
        "LinkedIn",
        "https://www.facebook.com/profile.php?id=100004085330435"
      ),
      svgList("DevTo", "https://dev.to/findlogan"),
    ],
  },
  {
    name: "Christian Vanloon",
    role: "Co-Founder & Senior Engineer",
    imageUrl: Christian,
    bio: `Greetings, my name is Christian Van Loon, and I am a Web Stack Developer who is committed to being your technology partner. I have a degree in MERN Full Stack Development from Bryan University, which has provided me with front-end and back-end expertise. I look forward to using my skills to bring your plans to life, I am committed to contributing to your success and helping you achieve your goals.`,
    icons: [
      svgList("GitHub", "https://github.com/WarMachine-282"),
      svgList(
        "LinkedIn",
        "https://www.linkedin.com/in/christian-van-loon-6b47731ab/"
      ),
      svgList("FaceBook", "https://www.facebook.com/christian.vanloon.7/"),
    ],
  },
];

export default function People() {
  return (
    <div className="lg:col-span-2">
      <ul className="space-y-6 sm:space-y-10 sm:-mt-0 sm:divide-y sm:divide-gray-200 lg:gap-x-8 lg:space-y-0">
        {people.map((person) => (
          <li key={person.name} className="py-6 sm:py-8">
            <div className="space-y-4 sm:grid sm:grid-cols-1 md:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
              <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4 mx-4 md:mx-0">
                <img
                  className="rounded-lg object-cover shadow-lg"
                  src={person.imageUrl}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:mt-0 md:col-span-2">
                <div className="space-y-4">
                  <div className="space-y-1 text-lg font-medium leading-6 px-4">
                    <h3>{person.name}</h3>
                    <p className="text-indigo-600">{person.role}</p>
                  </div>
                  <div className="text-lg">
                    <p className="text-gray-500 px-4">{person.bio}</p>
                  </div>
                  <ul className="flex flex-wrap space-x-5 px-4">
                    {person.icons.map((icon) => icon)}
                    {console.log(person.icons)}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
