import React from "react";

const CustomEmails = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Enhance Your Business Presence with Custom Emails
        </h2>
        <p className="text-lg text-white mb-8">
          Boost your credibility and professionalism with custom email addresses
          tailored to your brand.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Strengthen Your Brand
            </h3>
            <p className="text-lg text-blue-200">
              A custom email address that matches your domain enhances your
              brand's recognition and helps you stand out from the competition.
              Customers are more likely to trust and engage with your business
              when you present a consistent and professional image.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Increase Credibility
            </h3>
            <p className="text-lg text-blue-200">
              Custom email addresses help establish credibility and build trust
              with your clients and partners. Generic email addresses may be
              perceived as unprofessional, while custom emails demonstrate that
              you're serious about your business and its online presence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomEmails;
