import React from "react";

const SearchEngineOptimization = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Rank at the Top & Boost Performance with Search Engine Optimization
        </h2>
        <p className="text-lg text-white mb-8">
          We provide top-notch SEO services to help your website achieve higher
          rankings, improved performance, and increased visibility in search
          engine results.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Top Rankings
            </h3>
            <p className="text-lg text-blue-200">
              Our expert team uses data-driven strategies and industry best
              practices to optimize your website, ensuring it ranks at the top
              of search engine results for relevant keywords and phrases.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Performance Enhancement
            </h3>
            <p className="text-lg text-blue-200">
              We focus on improving your website's performance by optimizing
              technical aspects, such as load times, mobile-friendliness, and
              user experience, ensuring that both search engines and users have
              a seamless experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchEngineOptimization;
