import Statement from "../components/Contact/Statement";
import Form from "../components/Contact/Form";

export default function Contact() {
  return (
    <div className="contact-bg">
      <Statement />
      <Form />
    </div>
  );
}
