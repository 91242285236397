export default function Statement() {
  return (
    <div>
      <div className="team-bg">
        <div className="py-24 lg:py-32">
          <div className="relative mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl">
              Get in touch
            </h1>
            <p className="mt-6 max-w-3xl text-2xl text-warm-gray-500">
              We're here to help. Send us a message using the form below and
              we'll respond as soon as possible.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
