export default function AboutUs() {
  return (
    <div id="team-banner-bg" className="space-y-5 sm:space-y-4 p-8 ">
      <h2 className="text-3xl font-bold text-gray-100 tracking-tight sm:text-4xl">
        About Us
      </h2>
      <p className="text-lg text-gray-100">
        We understand that businesses lacking an online presence are at a
        significant disadvantage. Studies have shown that 70-80% of consumers
        research a company online before visiting or purchasing in person.
        Furthermore, 53% of users will leave if a website doesn’t load within 3
        seconds, and nearly 60% of users will leave even if the site loads
        quickly but is not mobile responsive In today’s digital landscape,
        mobile responsive, fast, And having an SEO-optimized website is
        important.
      </p>
      <p className="text-lg text-gray-100">
        At our company, our mission is to help you grow your business by
        increasing your online presence. We will walk you through the process of
        developing a high-performing, mobile-responsive website that not only
        attracts more visitors but also converts them into loyal customers. By
        doing so, we aim to increase the visibility of your business on the
        internet, drive more sales, and ultimately generate more revenue for
        your company.
      </p>
    </div>
  );
}
