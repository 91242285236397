import React from "react";
import "animate.css";

const TeamHero = () => {
  return (
    <div className="waves">
      <div className="team-header">
        <div className="team-inner-header flex-wrap">
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-16 lg:px-8"></div>
        </div>
      </div>
    </div>
  );
};
export default TeamHero;
