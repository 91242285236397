import React, { useEffect } from "react";
import {
  DocumentReportIcon,
  HandIcon,
  HeartIcon,
  InboxIcon,
  PencilAltIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { CogIcon } from "@heroicons/react/solid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const features = [
  {
    name: "Custom Emails",
    description:
      "Find your new email that looks professional with your domain that will give you credibility when emailing customers.",
    icon: InboxIcon,
  },
  {
    name: "Management Platforms",
    description:
      "Have customization easily at your fingertips to track sales, inventory, team members, and more with an easy-to-use dashboard.",
    icon: UsersIcon,
  },
  {
    name: "Filtering & Clean Input",
    description:
      "When growing a larger-scale application we understand that there is a lot of robots involved. We will do our best to filter spam & keep your services online.",
    icon: TrashIcon,
  },
  {
    name: "Content Writing",
    description:
      "Not sure what to say? We will write content that will capture the attention of your website visitors if you need that additional author.",
    icon: PencilAltIcon,
  },
  {
    name: "Reporting & Monitoring",
    description:
      "We offer to monitor the uptime & traffic of all websites. Get insight into how many unique visitors you are receiving.",
    icon: DocumentReportIcon,
  },
  {
    name: "Weekly Backups",
    description:
      "We offer to have weekly server back-ups. Your data is precious to your customers, keep your data backed up and safe.",
    icon: CogIcon,
  },
  {
    name: "User Training",
    description:
      "In more advanced applications, we may send someone to train your team on how to use the application.",
    icon: HandIcon,
  },
  {
    name: "Built With Care",
    description:
      "The web applications we build are built with care and scalability in mind. We are with you every step of the way.",
    icon: HeartIcon,
  },
];

export default function Features() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (typeof window !== "undefined" && gsap && ScrollTrigger) {
      // Check if the screen width is larger than 768px (mobile breakpoint)
      if (window.innerWidth > 768) {
        // Animate header and description
        const headerTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: ".feature_header",
            start: "top 75%", // Change the start value here
            end: "bottom 20%",
            scrub: true,
          },
        });

        headerTimeline.from("#fc-anim2", {
          y: "-50%",
          duration: 1,
          opacity: 0,
          ease: "power2.out",
        });

        // Animate individual features
        const featureTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: ".feature_header",
            start: "top 75%", // Change the start value here
            end: "bottom 75%",
            scrub: true,
          },
        });

        featureTimeline.from(".feature-item", {
          scale: 0.5,
          duration: 1,
          opacity: 0,
          stagger: 0.2, // Add stagger for smooth animation between items
          ease: "power2.out",
        });
      }
    }
  }, []);

  return (
    <div className="feature_header">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">
          Solutions for all of your technology needs
        </h2>
        <p className="mt-4 max-w-3xl text-md font-medium text-blue-200">
          We are here to help you build the best products to fit your business
          needs.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="feature-item">
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-md font-medium text-white">
                  {feature.name}
                </h3>
                <p className="mt-2 text-base text-blue-200">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
