import React from "react";

const ResponsiveDesign = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Responsive Web Design Tailored to Your Needs
        </h2>
        <p className="text-lg text-white mb-8">
          Our skilled team creates stunning and responsive web designs that
          adapt seamlessly to any device, ensuring an exceptional user
          experience for your clients, no matter how they access your site.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Customized Solutions
            </h3>
            <p className="text-lg text-blue-200">
              We collaborate with you to understand your unique business
              objectives and create tailor-made web designs that effectively
              convey your brand identity and message.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Mobile-First Approach
            </h3>
            <p className="text-lg text-blue-200">
              With a mobile-first approach, we ensure your website is designed
              to perform optimally on smartphones, tablets, and other devices,
              as well as desktops, for a truly responsive experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveDesign;
