export default function Thanks() {
  return (
    <div className="team-bg">
      <div className=" team-bg min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8" style={{minHeight: '70vh'}}>
        <div className="mx-auto max-w-max">
          <main>
          <div><p className="text-4xl text-center font-bold tracking-tight text-indigo-600 sm:text-5xl">Thank you!</p></div>
          <div><p className="text-2xl text-center tracking-tight text-gray-500 sm:text-3xl mt-6">We will reach out to you within 24-48 hours.</p></div>  
          </main>
        </div>
      </div>
    </div>
  );
}
