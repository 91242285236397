import React from "react";

const WebsiteMaintenance = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Ensure Quality & Boost Performance with Website Maintenance
        </h2>
        <p className="text-lg text-white mb-8">
          We provide comprehensive website maintenance services to keep your
          website up-to-date, secure, and performing at its best.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Quality Assurance
            </h3>
            <p className="text-lg text-blue-200">
              Our website maintenance services include regular quality checks to
              ensure your website remains user-friendly, visually appealing, and
              fully functional. We promptly address any issues or errors,
              maintaining a high-quality online presence for your brand.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Performance & Reach
            </h3>
            <p className="text-lg text-blue-200">
              We optimize your website's performance by addressing technical
              issues, updating content, and implementing SEO best practices. Our
              maintenance services ensure your website continues to reach more
              users and perform at its peak.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteMaintenance;
