import React, { useEffect } from "react";
import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";
import irrigation from "../../media/irrigation.png";
import volkov from "../../media/volkov.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Features() {
  useEffect(() => {
    // Disable animation for small screens (phones and tablets)
    if (typeof window !== "undefined" && window.innerWidth <= 768) {
      return;
    }

    // Check if GSAP and ScrollTrigger are available
    if (typeof window !== "undefined" && gsap && ScrollTrigger) {
      // Create GSAP timeline for the first animation
      const tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: "#feature-1",
          start: "top 90%", // Adjust start position to trigger animation earlier
          end: "bottom 60%",
          scrub: true,
        },
      });

      tl1.from("#feature-1", {
        x: "-100%",
        duration: 0.75, // Reduce duration to make animation faster
        opacity: 0,
        ease: "power2.out", // Add easing for smoother animation
      });

      // Create GSAP timeline for the second animation
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: "#feature-2",
          start: "top 90%", // Adjust start position to trigger animation earlier
          end: "bottom 70%",
          scrub: true,
        },
      });

      tl2.from("#feature-2", {
        x: "100%",
        duration: 0.75, // Reduce duration to make animation faster
        opacity: 0,
        ease: "power2.out", // Add easing for smoother animation
      });
    }
  }, []);

  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div id="feature-1">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-600">
                  <InboxIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Professional Grade Responsive Web Design
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  First impressions matter, especially when it comes to engaging
                  potential clients or customers. You only have{" "}
                  <strong>7-15</strong> seconds to pique their interest. A
                  slow-loading or outdated website can drive visitors away
                  before they have a chance to explore. That's why it's
                  important to have a modern, visually appealing design that
                  quickly communicates your purpose and entices visitors to stay
                  and learn more.
                </p>
                <div className="mt-6">
                  <a
                    href="/contact"
                    className="inline-flex px-4 py-2 border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                    aria-label="Reach More People Today"
                  >
                    Reach More People Today
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    “I am so happy I trusted Web Factory with developing my
                    website, business has been booming and I garnered a far
                    further reach!”
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0"></div>
                    <div className="text-base font-medium text-gray-700">
                      - Anthony Settle, Irrigation Plus Owner
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={irrigation}
                alt="Irrigation Plus website example"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div
          id="feature-2"
          className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
        >
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-blue-600">
                  <SparklesIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  SEO Optimization
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  SEO optimization is an ever-changing landscape, and staying
                  ahead is essential for your website's success. We're here to
                  help you boost your performance and attract more traffic. With
                  the latest focus on page experience factors, we ensure that
                  your website is not only accessible and fast but also primed
                  to rank higher in search results, giving you a competitive
                  edge.
                </p>
                <div className="mt-6">
                  <a
                    href="/contact"
                    className="inline-flex px-4 py-2 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                    aria-label="Get started with SEO optimization"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={volkov}
                alt="SEO optimization example"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
