import React from "react";

const Automation = () => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-700 py-20 px-4">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">
          Empower Your Business with Automation
        </h2>
        <p className="text-lg text-white mb-8">
          Streamline your business processes with custom automation solutions,
          saving time, money, and labor.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Save Time & Money
            </h3>
            <p className="text-lg text-blue-200">
              Automation reduces manual tasks, freeing up your team to focus on
              more strategic initiatives. By automating repetitive tasks, you
              can save time and reduce labor costs, ultimately increasing your
              bottom line.
            </p>
          </div>
          <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Improve Efficiency & Accuracy
            </h3>
            <p className="text-lg text-blue-200">
              Automating processes eliminates human error, ensuring consistent
              and accurate results. This not only improves efficiency, but also
              enhances the overall quality of your products and services,
              leading to higher customer satisfaction.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automation;
